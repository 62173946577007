import React from "react";

export default () => (
  <footer id="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <span className="copyright">Copyright &copy; SpinFlip LLC 2019</span>
        </div>
        <div className="col-md-4">
          <ul className="list-inline social-buttons">
            <li className="list-inline-item">
              <a href="https://twitter.com/Spin_Flip">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.facebook.com/SpinFlip-LLC-2205108403074766/">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.linkedin.com/company/spinflip-llc/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="mailto:info@spin-flip.com">
                <i className="far fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-4">
          <ul className="list-inline quicklinks">
            <li className="list-inline-item">
              <a href="/racing">Privacy Policy</a>
            </li>
            <li className="list-inline-item">
              <a href="/racing">Terms of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);
