import React from "react";

import { toDollars } from "../../utils";

export default ({ product }) => (
  <div className="product flex-column">
    <h3>{product.name}</h3>
    <img className="race-img" alt="" src={product.img} />
    <div>
      <p>Price:</p>
      <p>{toDollars(product.cost)}</p>
    </div>
    <div>
      <p>Weight:</p>
      <p>{product.weight}</p>
    </div>
    <div>
      <p>Inventory:</p>
      <p>{product.inventory}</p>
    </div>
    <div>
      <p>{product.description}</p>
    </div>
    <button>Add to cart</button>
  </div>
);
