import React from "react";
import CartProduct from "../components/common/CartProduct";
import { totalPrice } from "../utils";
import PriceSummary from "../components/common/PriceSummary";

const products = [
  {
    id: 1,
    name: "Four Leaf Clover",
    description: "The most magical clover",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 499,
    weight: 4,
    inventory: 5
  },
  {
    id: 2,
    name: "Magically Imbued Short Sword",
    description: "Dispatch any threat with this magical blade",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 39999,
    weight: 25,
    inventory: 10
  },
  {
    id: 3,
    name: "Discombobulator",
    description: "Discombobulate to your heart's content",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 1899,
    weight: 4,
    inventory: 1
  },
  {
    id: 4,
    name: "Ambrosia",
    description: "The nectar of the god's in this convenient 1 ounce serving",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 199,
    weight: 2,
    inventory: 100
  }
];

const totalProductPrice = totalPrice(products);
const ohioSalesTax = 0.0575;
const tax = totalPrice(products) * ohioSalesTax;
const totalCartPrice = totalProductPrice + tax;

export default () => {
  const body = (
    <div className="flex-center">
      <div className="cart-products">
        {products &&
          products.map(product => {
            return <CartProduct product={product} />;
          })}
      </div>
      <div className="cart-total-summary">
        <PriceSummary
          totalProductPrice={totalProductPrice}
          shippingCost={null}
          tax={tax}
          totalPrice={totalCartPrice}
        />
      </div>
    </div>
  );

  return body;
};
