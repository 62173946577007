export default () => [
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Arizona Grand Prix",
    date: "12/12/12 - 12/12/12",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout going straight with a mountain in between two roads to jump across and a fire pit under a bridge shortcut"
  },
  {
    img: "/images/portfolio/brake-cooling-duct.jpg",
    title: "California Rifle Company",
    date: "date",
    name: "Track Name",
    description: "bends and turns with whirlies and spinnies althroughout"
  },
  {
    img: "/images/portfolio/GlassGuardian02.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/splash_bg.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description:
      "bends and turns with whirlies and spinnies althroughout pointing down and around"
  },
  {
    img: "/images/portfolio/restockgeniePCB.jpg",
    title: "Title",
    date: "date",
    name: "Track Name",
    description: "bends and turns with whirlies and spinnies althroughout"
  }
];
