const NODE_ENV = process.env.NODE_ENV || 'development';
const API_URL = process.env.API_URL || 'localhost:5000';
const COGNITO_CLIENT_ID =
  NODE_ENV === 'production'
    ? '4bqpueol482hcgre1gf0luaph8'
    : '2uriqe637f4j2ag5scrruqija3';
const LOGIN_URL =
  NODE_ENV === 'production'
    ? `https://auth.${API_URL}/login?client_id=${COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=https://${API_URL}/`
    : `https://${NODE_ENV}-auth.spin-flip.com/login?${COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${API_URL}/`;

export default {
  API_URL,
  COGNITO_CLIENT_ID,
  LOGIN_URL,
  NODE_ENV,
  SERVER_NAME: process.env.SERVER_NAME || 'localhost:3000'
};
