import React, { useState } from "react";
import Form from "./Form";

export default ({ product, handleSubmit }) => {
  const [name, setName] = useState(product.name);
  const [description, setDescription] = useState(product.description);
  const [img, setImg] = useState("");
  const [cost, setCost] = useState(product.cost);
  const [weight, setWeight] = useState(product.weight);
  const [inventory, setInventory] = useState(product.inventory);

  const params = {
    name,
    description,
    img,
    cost,
    weight,
    inventory
  };

  const productForm = (
    <Form handleSubmit={handleSubmit} params={params}>
      <label>name</label>
      <input
        type="text"
        value={name}
        name="name"
        onChange={event => setName(event.target.value)}
      />
      <label>description</label>
      <textarea
        value={description}
        name="description"
        onChange={event => setDescription(event.target.value)}
      />
      <label>image</label>
      <input
        type="file"
        value={img}
        name="image"
        onChange={event => setImg(event.target.value)}
      />
      <label>cost</label>
      <input
        type="number"
        value={cost}
        name="cost"
        onChange={event => setCost(event.target.value)}
      />
      <label>weight</label>
      <input
        type="number"
        value={weight}
        name="weight"
        onChange={event => setWeight(event.target.value)}
      />
      <label>inventory</label>
      <input
        type="number"
        value={inventory}
        name="inventory"
        onChange={event => setInventory(event.target.value)}
      />
    </Form>
  );

  return productForm;
};
