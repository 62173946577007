import React from "react";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

export default ({ body }) => (
  <div id="page">
    <Header />
    <Main body={body} />
    <Footer />
  </div>
);
