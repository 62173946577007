import React from "react";
import Product from "../../components/common/Product";

import { products } from "./productsMock";

export default () => {
  // const products = [];

  const body = (
    <div className="products flex-center">
      {products &&
        products.map(product => {
          return <Product product={product} />;
        })}
    </div>
  );

  return body;
};
