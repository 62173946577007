import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RacingPage from "../../components/common/RacingPage";
import AdminRoute from "../../AdminRoute";
import editProductContent from "./editProductContent";
import newProductContent from "./newProductContent";
import productsIndexContent from "./productsIndexContent";
import showProductContent from "./showProductContent";

const ProductsRouter = ({ match }) => {
  return (
    <Router>
      <Switch>
        <Route
          path={`${match.url}/index`}
          component={() => <RacingPage body={productsIndexContent()} />}
          exact
        />
        <Route
          path={`${match.url}/show/:id`}
          component={() => <RacingPage body={showProductContent()} />}
          exact
        />
        <AdminRoute
          path={`${match.url}/new`}
          component={() => <RacingPage body={newProductContent()} />}
          exact
        />
        <AdminRoute
          path={`${match.url}/edit/:id`}
          component={() => <RacingPage body={editProductContent()} />}
          exact
        />
      </Switch>
    </Router>
  );
};

export default ProductsRouter;
