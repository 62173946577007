import React from "react";

export default () => {
  const body = (
    <div>
      <header className="masthead">
        <div className="container">
          <div className="intro-text">
            <div className="intro-lead-in">Welcome To Our Lab!</div>
            <div className="intro-heading text-uppercase">
              Need an Engineer?
            </div>
            <a
              className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
              href="#services"
            >
              Learn More
            </a>
          </div>
        </div>
      </header>

      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Services</h2>
              <h3 className="section-subheading text-muted">
                Of course we can do anything you ask, but these are some of the
                things we love to work on.
              </h3>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-network-wired fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">IoT Development</h4>
              <p className="text-muted">
                Let us help you build custom distributable, and updateable
                software that runs on small, internet connected devices. We can
                engineer a professional product that is reliable and fault
                tolerant.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-microchip fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Custom PCB Design</h4>
              <p className="text-muted">
                Our engineers use software such as Eagle to design PCBs. We've
                build many products to support our IoT clients. From design to
                board, we can custom design a hardware solution for your needs.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fas fa-desktop fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Web Applications</h4>
              <p className="text-muted">
                We have full stack developers that and build custom web
                applications or integrations for your business. Our preferred
                technologies include languages such as Elixir, Erlang, and
                Python.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light" id="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Portfolio</h2>
              <h3 className="section-subheading text-muted">
                Here are a few examples of our history.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 portfolio-item">
              <img
                className="img-fluid"
                src="/images/portfolio/restockgeniePCB.jpg"
                alt=""
              />
              <div className="portfolio-caption">
                <span>RF Enabled Microcontrollers</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <img
                className="img-fluid"
                src="/images/portfolio/GlassGuardian02.jpg"
                alt=""
              />
              <div className="portfolio-caption">
                <span>Prototype Molding/Manufacturing</span>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <img
                className="img-fluid"
                src="/images/portfolio/brake-cooling-duct.jpg"
                alt=""
              />
              <div className="portfolio-caption">
                <span>Race Car Brake Cooling Duct</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light" id="team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Our Team</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-md-6 col-lg-4">
              <div className="team-member">
                <img
                  className="mx-auto rounded-circle"
                  src="/images/team/herman.jpg"
                  alt=""
                />
                <h4>Herman Singh</h4>
                <p className="text-muted">Founder & CEO</p>
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    <a href="https://twitter.com/SinghHermann">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://github.com/Kartstig">
                      <i className="fab fa-github"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/herman-singh-6784612b/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="team-member">
                <img
                  className="mx-auto rounded-circle"
                  src="/images/team/chadhead.png"
                  alt=""
                />
                <h4>Chad Ludman</h4>
                <p className="text-muted">Embedded Systems Developer</p>
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    <a href="https://github.com/ludmanchad">
                      <i className="fab fa-github"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/chad-ludman-3bb23876/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img
                className="img-fluid d-block mx-auto logo-height"
                src="/images/logos/des.png"
                alt=""
              />
            </div>
            <div className="col-sm-6">
              <img
                className="img-fluid d-block mx-auto logo-height"
                src="/images/logos/nyulmc.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Contact Us</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <form id="contactForm" name="sentMessage" noValidate={true}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Your Name *"
                        required={true}
                        data-validation-required-message="Please enter your name."
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        placeholder="Your Email *"
                        required={true}
                        data-validation-required-message="Please enter your email address."
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="phone"
                        type="tel"
                        placeholder="Your Phone *"
                        required={true}
                        data-validation-required-message="Please enter your phone number."
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="message"
                        placeholder="Your Message *"
                        required={true}
                        data-validation-required-message="Please enter a message."
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-lg-12 text-center">
                    <div id="success"></div>
                    <button
                      id="sendMessageButton"
                      className="btn btn-primary btn-xl text-uppercase"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  return body;
};
