import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import '../css/checkout.css';

const PRODUCT_SKU =
  process.env.NODE_ENV === 'production'
    ? 'sku_H3lzWCgiTnuN4p'
    : 'sku_H3oVeeb8wH55we';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [qty, setQty] = useState(1);

  const handleSubmit = async (event, quantity) => {
    console.log(quantity);
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    await stripe.redirectToCheckout({
      items: [{ sku: PRODUCT_SKU, quantity: parseInt(quantity) }],

      // Do not rely on the redirect to the successUrl for fulfilling
      // purchases, customers may not always reach the success_url after
      // a successful payment.
      // Instead use one of the strategies described in
      // https://stripe.com/docs/payments/checkout/fulfillment
      successUrl: 'https://spin-flip.com/stripe/success',
      cancelUrl: 'https://spin-flip.com/stripe/canceled',
    });
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-sm'>
          <div className='text-center'>
            <img
              alt='Coleus Burgundy'
              className='rounded mx-auto d-bloc'
              style={{ marginTop: '4em', maxHeight: '10em' }}
              src='https://i.imgur.com/qtXpSTY.jpg'
            />
          </div>

          <div className='text-center'>
            <p>$5.00/ea</p>
          </div>
        </div>

        <div className='col-sm'>
          <form onSubmit={(e) => handleSubmit(e, qty)}>
            <div className='row'>
              <div className='col-sm'>
                <div className='form-inline float-right'>
                  <label htmlFor='qty'>Qty: </label>
                  <input
                    name='quantity'
                    value={qty}
                    onChange={(e) =>
                      setQty(e.target.value >= 1 ? e.target.value : qty)
                    }
                    type='number'
                    style={{
                      marginLeft: '2em',
                      marginRight: '2em',
                      maxWidth: '5em',
                      fontSize: '18px',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm'>
                <span>
                  <span
                    className='float-left'
                    style={{ fontSize: '1.5em', marginLeft: '10%' }}>
                    Total:{' '}
                  </span>

                  <span
                    className='float-right'
                    style={{ fontSize: '1.5em', marginRight: '10%' }}>
                    {(qty * 5.0).toFixed(2)}
                  </span>
                </span>
              </div>
            </div>

            {/* <div className='col-sm'>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </div> */}

            <div className='text-center'>
              <button className='pay-btn' type='submit' disabled={!stripe}>
                Buy
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
