import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { UnauthenticatedContext } from "../App";
import Form from "../components/common/Form";

export default () => {
  const { unauthenticatedApi } = useContext(UnauthenticatedContext);
  const { push } = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (event, user) => {
    event.preventDefault();

    if (user.password === user.confirmPassword) {
      unauthenticatedApi
        .signup(user)
        .then(() => {
          push("/login");
        })
        .catch(error => error.message);
    }
  };

  const user = {
    email,
    password,
    confirmPassword
  };

  const body = (
    <Form handleSubmit={handleSubmit} params={user}>
      <label>Email</label>
      <input
        type="text"
        value={email}
        name="email"
        onChange={event => setEmail(event.target.value)}
      />
      <label>Password</label>
      <input
        type="password"
        value={password}
        name="password"
        onChange={event => setPassword(event.target.value)}
      />
      <label>Confirm Password</label>
      <input
        type="password"
        value={confirmPassword}
        name="confirmPassword"
        onChange={event => setConfirmPassword(event.target.value)}
      />
    </Form>
  );

  return body;
};
