import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthenticatedContext } from './App';
import { isAdmin } from './utils';
import { setToken } from './reducers/actions';
import CONFIG from './config';

const isAuthenticatedAdmin = (authenticatedApi, dispatch) => {
  const token = localStorage.getItem('token');

  if (token && isAdmin(token)) {
    const response = authenticatedApi.verifyJWT(token);

    switch (response.status) {
      case 200:
        setToken(response.data.token, dispatch);
        return true;
      case 403:
        return false;
      default:
        return false;
    }
  }
  return false;
};

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { authenticatedApi, dispatch } = useContext(AuthenticatedContext);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticatedAdmin(authenticatedApi, dispatch) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: CONFIG.LOGIN_URL,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
