import { AxiosInstance } from "axios";
import apiClient from "./apiClient";

class AuthenticatedApi {
  apiClient: AxiosInstance;

  constructor() {
    apiClient.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;

    this.apiClient = apiClient;
  }

  getCart = userId =>
    new Promise((resolve, reject) => {
      this.apiClient
        .post("/cart", { user_id: userId })
        .then(response =>
          resolve({ data: response.data, status: response.status })
        )
        .catch(error =>
          reject({
            message: error.message,
            status: error.response.status
          })
        );
    });

  verifyJWT = token =>
    new Promise((resolve, reject) => {
      this.apiClient
        .post("/verify_token", { token: token })
        .then(response =>
          resolve({ data: response.data, status: response.status })
        )
        .catch(error =>
          reject({
            message: error.message,
            status: error.response.status
          })
        );
    });

  insertProduct = product =>
    new Promise((resolve, reject) => {
      this.apiClient
        .post("/products/new", { product: product })
        .then(response =>
          resolve({ data: response.data, status: response.status })
        )
        .catch(error =>
          reject({
            message: error.message,
            status: error.response.status
          })
        );
    });

  updateProduct = product =>
    new Promise((resolve, reject) => {
      this.apiClient
        .post(`/products/edit/${product.id}`, { product: product })
        .then(response =>
          resolve({ data: response.data, status: response.status })
        )
        .catch(error =>
          reject({
            message: error.message,
            status: error.response.status
          })
        );
    });
}

export default AuthenticatedApi;
