import React from "react";

export default ({ imgUrl, eventTitle, trackName, date, trackDescription }) => {
  const card = (
    <div className="event">
      <img src={imgUrl} alt="" className="race-img" />
      <h6>{eventTitle}</h6>
      <p>{date}</p>
      <p>{trackName}</p>
      <br />
      <p>{trackDescription}</p>
      <br />
      <button>Event Stats</button>
    </div>
  );
  return card;
};
