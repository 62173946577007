import React from 'react';

class StripeSuccess extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='jumbotron text-center'>
          <h1 className='display-3'>Thank You!</h1>
          <p className='lead'>We appreciate your business</p>
          <hr />
          <p>
            Having trouble?{' '}
            <a className='link' href='mailto:herman@spin-flip.com'>
              Contact us
            </a>
          </p>
          <p className='lead'>
            <a className='btn btn-primary btn-sm' href='/' role='button'>
              Continue to homepage
            </a>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default StripeSuccess;
