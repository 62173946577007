import React from "react";

import { product } from "./productsMock";
import ProductDetails from "../../components/common/ProductDetails";

export default () => {
  const body = (
    <div className="flex-center">
      <ProductDetails product={product} />
    </div>
  );

  return body;
};
