import React, { useState } from "react";

export default ({ handleSubmit, params, children }) => {
  const form = (
    <form
      className="flex-column"
      onSubmit={event => handleSubmit(event, params)}
    >
      {children}
    </form>
  );

  return form;
};
