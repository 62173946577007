export const products = [
  {
    id: 1,
    name: "Four Leaf Clover",
    description: "The most magical clover, beware of the lucky charms",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 499,
    weight: 4,
    inventory: 5
  },
  {
    id: 2,
    name: "Magically Imbued Short Sword",
    description: "Dispatch any threat with this magical blade",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 39999,
    weight: 25,
    inventory: 10
  },
  {
    id: 3,
    name: "Discombobulator",
    description: "Discombobulate to your heart's content",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 1899,
    weight: 4,
    inventory: 10
  },
  {
    id: 4,
    name: "Ambrosia",
    description: "The nectar of the god's in this convenient 1 ounce serving",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 199,
    weight: 2,
    inventory: 100
  },
  {
    id: 5,
    name: "The Matrix: Reimagined",
    description:
      "Your very own rendition of the popular 'Matrix' series, starring you as the character of your choice!",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 99999999,
    weight: 2,
    inventory: 1
  },
  {
    id: 6,
    name: "Unknown Entity",
    description: "Who knows?",
    img: "/images/portfolio/restockgeniePCB.jpg",
    cost: 1337,
    weight: 2,
    inventory: 1337
  }
];

export const product = {
  id: 1,
  name: "Four Leaf Clover",
  description: "The most magical clover",
  img: "/images/portfolio/restockgeniePCB.jpg",
  cost: 499,
  weight: 4,
  inventory: 5
};
