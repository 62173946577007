import React from "react";

import RacingHeader from "./RacingHeader";
import RacingBody from "./RacingBody";
import RacingFooter from "./RacingFooter";

export default ({ body }) => (
  <div id="racing-page">
    <RacingHeader />
    <RacingBody body={body} />
    <RacingFooter />
  </div>
);
