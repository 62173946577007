import React from "react";
import { toDollars } from "../../utils";

export default ({ totalProductPrice, tax, totalPrice, shippingCost }) => {
  return (
    <div className="">
      <p>Item Total: {toDollars(totalProductPrice)}</p>
      {shippingCost && <p>Shipping Cost: $$$</p>}
      <p>Tax: {toDollars(tax)}</p>
      <p>Total: {toDollars(totalPrice)}</p>
    </div>
  );
};
