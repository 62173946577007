import React from "react";
import EventCard from "../components/common/EventCard";
import events from "./racesMock";

export default () => {
  const body = (
    <div className="horizontal-scroll">
      <div className="events">
        {events &&
          events().map(event => {
            return (
              <EventCard
                imgUrl={event.img}
                eventTitle={event.title}
                date={event.date}
                trackName={event.name}
                trackDescription={event.description}
              />
            );
          })}
      </div>
    </div>
  );

  return body;
};
