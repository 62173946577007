import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ProductForm from "../../components/common/ProductForm";
import { AuthenticatedContext } from "../../App";
import * as Type from "../../constants/types";

export default () => {
  const { push } = useHistory();
  const { authenticatedApi } = useContext(AuthenticatedContext);

  const handleSubmit = (event, product) => {
    event.preventDefault();

    authenticatedApi
      .insertProduct(product)
      .then((response: any) => {
        push(`/products/show/${response.data.product.id}`);
      })
      .catch(error => error.message);
  };

  const blankProduct = {
    name: "",
    description: "",
    img: "",
    cost: null,
    weight: null,
    inventory: null
  };

  const body = (
    <ProductForm product={blankProduct} handleSubmit={handleSubmit} />
  );

  return body;
};
