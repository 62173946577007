import * as TYPE from '../constants/types';

export const totalPrice = (products: Array<TYPE.Product>): number =>
  products.map(product => product.cost).reduce((acc, val) => acc + val);

export const toDollars = (pennies: number): string => {
  const dollars = pennies / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

export const isAdmin = jwt => {
  return jwt ? JSON.parse(atob(jwt.split('.')[1]))['role'] === 'admin' : false;
};
