export const setToken = async (token, dispatch) => {
  try {
    localStorage.setItem("token", token);
    dispatch({ type: "setToken", token: token });
  } catch (error) {
    dispatch({ type: "Error", error });
  }
};

export const setCart = async (cartId, dispatch, networkApi) => {
  try {
    const response = await networkApi.getCart(cartId);

    dispatch({ type: "setCart", cart: response.data.cart });
  } catch (error) {
    dispatch({ type: "Error", error });
  }
};
