import React from 'react';
import { Link } from 'react-router-dom';

class StripeCancel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Link to={'/shop/coleus'}>Go back</Link>
      </React.Fragment>
    );
  }
}

export default StripeCancel;
