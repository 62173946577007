export function hasValidToken() {
  const token = localStorage.getItem('token');
  if (token === undefined) return false;
  const claims = extractClaimsFromToken(token);
  if (claims.user_id) {
    return true;
  }
  return false;
}

function extractClaimsFromToken(token) {
  return JSON.parse(atob(token.split('.')[1]));
}
