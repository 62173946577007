import React from "react";
import { useHistory } from "react-router-dom";

import { toDollars } from "../../utils";

const viewItem = (productId, push) => {
  return push(`/shop/show/${productId}`);
};

export default ({ product }) => {
  const { push } = useHistory();

  return (
    <div className="product flex-column">
      <h3>{product.name}</h3>
      <img className="race-img" alt="" src={product.img} />
      <div>
        <p>Price:</p>
        <p>{toDollars(product.cost)}</p>
      </div>
      <div>
        <p>{product.description}</p>
      </div>
      <button onClick={() => viewItem(product.id, push)}>View Item</button>
      <button>Add to cart</button>
    </div>
  );
};
