// eslint-disable-next-line no-unused-vars
export const initialState = {
  isError: false,
  isLoading: false,
  cart: null,
  token: null,
  error: null
};

export default (state, action) => {
  switch (action.type) {
    case "isLoading":
      return { ...state, isLoading: action.isLoading };
    case "networkApiError":
      return { ...state, isError: true, isLoading: false, error: action.error };
    case "setCart":
      return { ...state, isLoading: false, cart: action.cart };
    case "setToken":
      return { ...state, isLoading: false, token: action.token };
    default:
      return state;
  }
};
