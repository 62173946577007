import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { hasValidToken } from '../auth';

import CONFIG from '../config';

export const PrivateRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasValidToken() ? (
        props.component()
      ) : (
        <Redirect
          to={{ pathname: CONFIG.LOGIN_URL, state: { from: props.location } }}
        />
      )
    }
  />
);

export default PrivateRoute;
