import React, { useReducer } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './css/app.css';
import history from './history';
import AuthenticatedApi from './services/authenticatedApi';
import NetworkApiReducer, { initialState } from './reducers/NetworkApiReducer';
import { ApplicationState, ApplicationReducer } from './constants/types';
import Page from './components/common/Page';
import HomePage from './content/HomePage';
import RacingPage from './components/common/RacingPage';
import PrivateRoute from './components/PrivateRoute';
import EventsContent from './content/EventContent';
import CartContent from './content/CartContent';
import SignupContent from './content/SignupContent';

import StripeCheckout from './pages/StripeCheckout';
import StripeSuccess from './pages/StripeSuccess';
import StripeCancel from './pages/StripeCancel';

import ProductsRouter from './content/Products';

export const AuthenticatedContext = React.createContext<any | null>(null);
export const UnauthenticatedContext = React.createContext<any | null>(null);
export const StateContext = React.createContext<ApplicationState | null>(null);

const ENV = process.env.NODE_ENV;

// Google Analytics
if (ENV === 'production') {
  ReactGA.initialize('UA-140083558-1');
  history.listen((location) => ReactGA.pageview(location.pathname));
}

const stripePromise =
  ENV === 'production'
    ? loadStripe('pk_live_hfxAGdwJmukhyocDz4yAiNBW00UBOjMSgo')
    : loadStripe('pk_test_UDLELwUUC9yiAK8lxFepWfvI008RIrvxJQ');

const App: React.FC = () => {
  const authenticatedApi = new AuthenticatedApi();

  const [applicationState, dispatch] = useReducer<ApplicationReducer>(
    NetworkApiReducer,
    initialState
  );

  const dispatchWithAuthenticatedApi = {
    dispatch,
    authenticatedApi,
  };

  return (
    <AuthenticatedContext.Provider value={dispatchWithAuthenticatedApi}>
      <StateContext.Provider value={applicationState}>
        <Router>
          <Switch>
            <Route
              path='/'
              component={() => <Page body={HomePage()} />}
              exact
            />
            <Route
              path='/racing'
              component={() => <RacingPage body={EventsContent()} />}
              exact
            />
            <Route
              path='/signup'
              component={() => <RacingPage body={SignupContent()} />}
              exact
            />
            >
            <Elements stripe={stripePromise}>
              <Route
                path='/shop'
                render={(props) => <ProductsRouter {...props} />}
              />
              <Route
                exact
                path='/shop/coleus'
                component={() => <StripeCheckout />}
              />

              <Route
                exact
                path='/stripe/success'
                component={() => <StripeSuccess />}
              />
              <Route
                exact
                path='/stripe/cancel'
                component={() => <StripeCancel />}
              />
            </Elements>
            <PrivateRoute
              path='/cart'
              component={() => <RacingPage body={CartContent()} />}
              exact
            />
          </Switch>
        </Router>
      </StateContext.Provider>
    </AuthenticatedContext.Provider>
  );
};

export default App;
