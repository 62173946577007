import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthenticatedContext } from "../../App";
import * as Type from "../../constants/types";

import { product } from "./productsMock";
import ProductForm from "../../components/common/ProductForm";

export default () => {
  const { push } = useHistory();
  const { authenticatedApi } = useContext(AuthenticatedContext);

  const handleSubmit = (event, product) => {
    event.preventDefault();

    authenticatedApi
      .updateProduct(product)
      .then((response: any) => {
        push(`/products/show/${response.data.product.id}`);
      })
      .catch(error => error.message);
  };

  const body = <ProductForm product={product} handleSubmit={handleSubmit} />;

  return body;
};
