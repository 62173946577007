import React from "react";

export default () => (
  <nav
    className="racing-header navbar navbar-expand-lg bg-dark fixed-top"
    id="mainNav"
  >
    <div className="container">
      <a className="navbar-brand js-scroll-trigger" href="/#page-top">
        SpinFlip
      </a>
      <button
        className="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        Menu
        <i className="fas fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav text-uppercase ml-auto">
          <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href="/racing">
              Events
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href="/drivers">
              Drivers
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href="/cars">
              Cars
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href="/shop/index">
              Shop
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href="/login">
              Login
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll-trigger" href="/cart">
              <i className="fas fa-shopping-cart"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);
