import React from "react";

import { toDollars } from "../../utils";

export default ({ product }) => (
  <div className="flex-column">
    <div>
      <p>{product.name}</p>
      <p>
        Quantity:{" "}
        <select>
          <option>1</option>
        </select>
      </p>
    </div>
    <img className="race-img" alt="" src={product.img} />
    <div>
      <p>Price:</p>
      <p>{toDollars(product.cost)}</p>
    </div>
  </div>
);
